<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            5. Аварийные режимы работы электроустановок.
          </v-card-title>
        </v-col>
        <v-col cols="12">
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
              color="red"
              icon="mdi-alert"
              class="title font-weight-light"
              >
                Аварийный режим работы электроустановки- режим, который сопровождается отклонением рабочих параметров от предельно допустимых значений. Характеризуется повреждением элементов системы электроснабжения, выходом из строя электрооборудования, перерывом электроснабжения.
            </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card-title>
            К аварийным режимам работы электроустановок относят:
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog1"
            width="60%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-15"
                v-bind="attrs"
                v-on="on"
                color="transparent"
                elevation-none

              >
                <v-img
                  lazy-src="img/material/flash-color.svg"
                  max-width="80"
                  height="80"
                  src="img/material/flash-color.svg"
                  class="mr-5"
                  contain
                ></v-img>
                <v-spacer></v-spacer>
                Короткие замыкания
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline" dark>
                Короткое замыкание
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">Короткое замыкание (КЗ)</span>- электрическое соединение двух точек электрической цепи с различными значениями потенциала, не предусмотренное конструкцией устройства и нарушающее его нормальную работу.
              </v-card-text>
              <Modal1></Modal1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog2"
            width="60%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-15"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-img
                  lazy-src="img/material/res-color.svg"
                  max-width="80"
                  height="80"
                  src="img/material/res-color.svg"
                  class="mr-5"
                  contain
                ></v-img>
                <v-spacer></v-spacer>
                Переходные сопротивления
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Переходное сопротивление
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">Переходное сопротивление </span>– сопротивление, возникающее в местах перехода тока с одного провода на другой или с провода на электроаппарат при наличии плохого контакта в местах соединений
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on }">
                      <span
                        class="blue lighten-4"
                        v-on="on"
                      >оконцеваний.</span>
                    </template>
                    <span class="pa-5 subtitle-1 "><strong>Оконцевание проводов</strong>- опресовка, сварка, пайка концов проводов или крепление болтовыми и трубчатыми сжимами. <br> Обеспечивает надежный электрический контакт и механическую прочность.</span>
                  </v-tooltip>
                  <Modal2 class="mt-5"></Modal2>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog3"
            width="60%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-15"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-img
                  lazy-src="img/material/burning-socket.svg"
                  max-width="80"
                  height="80"
                  src="img/material/burning-socket.svg"
                  class="mr-5"
                  contain
                ></v-img>
                <v-spacer></v-spacer>
                Перегрузки
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline" dark>
                Перегрузки
              </v-card-title>
              <Modal3></Modal3>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="6">
          <v-dialog
            v-model="dialog4"
            width="60%"
            class="pa-10"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                class="pa-15"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-img
                  lazy-src="img/material/cable-color.svg"
                  max-width="60"
                  height="60"
                  src="img/material/cable-color.svg"
                  contain
                ></v-img>
                <v-spacer></v-spacer>
                Искрение и появление электродуги
              </v-btn>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Искрение и электродуга
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">Электрическая дуга</span>- электрический разряд в газовой среде между контактами, возникающий при размыкании электрического контакта или при нестабильности переходного сопротивления контактов (искрение).
              </v-card-text>

              <v-card-title>
                Искрение наблюдается:
              </v-card-title>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                при размыкании электрических цепей под нагрузкой (например, когда вынимается электровилка из электророзетки);
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                при пробое изоляции между проводниками, при наличии плохих контактов;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                в местах соединения и оконцевания проводов и кабелей.
              </v-card-text>

                <v-card-text class="title font-weight-light mt-5">
                  <p>Под действием электрического поля воздух между контактами ионизируется, при достаточной величине напряжения происходит разряд, который сопровождается свечением воздуха и треском (тлеющий разряд).</p>
                  <p>С увеличением напряжения тлеющий разряд переходит в искрово, а при достаточной мощности может быть в виде электрической дуги.</p>
                  <p>При наличии в помещении горючих веществ или взрывоопасных смесей искры и электродуги могут быть причиной пожара и взрыва.</p>
                </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <Hint></Hint>
        <v-col cols="9">
          <v-card-title>
            Чтобы не допустить возникновения пожара от электроустановок, необходимо:
          </v-card-title>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            правильно выбирать электрооборудование, т.е. его конструктивное соответствие характеру окружающей среды, технологии производства;
          </v-card-text>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            осуществлять правильный монтаж и эксплуатацию;
          </v-card-text>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            применять аппараты защиты;
          </v-card-text>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            проводить инженерные расчеты;
          </v-card-text>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
           соблюдать режимные мероприятия.
          </v-card-text>
        </v-col>
        <v-col cols="3">
          <v-card flat class="d-flex flex-column justify-center">
            <v-img
              lazy-src="img/material/el-shield.svg"
              max-width="300"
              height="300"
              src="img/material/el-shield.svg"
              class="ma-10 pa-5"
              contain
              absolute
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Modal1 from '@/components/Page16/Modal1.vue'
import Modal2 from '@/components/Page16/Modal2.vue'
import Modal3 from '@/components/Page16/Modal3.vue'
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: false,
    dialog2: false,
    dialog3: false,
    dialog4: false
  }),
  components: {
    Modal1,
    Modal2,
    Modal3,
    Hint
  }
}
</script>
