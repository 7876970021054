<template>
  <div>
    <v-container class="noselect">
      <v-row>
        <v-col cols="12">
          <v-tabs
          v-model="tab1"
          grow
          color="red"
          >
            <v-tab class="red lighten-5 mr-5">Причины</v-tab>
            <v-tab class="red lighten-5 mr-5">Последствия</v-tab>
            <v-tab class="red lighten-5 mr-5">Методы защиты</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12">
          <v-tabs-items v-model="tab1">
            <v-tab-item>
              <v-card-title>
                Главная причина КЗ: нарушение изоляций электрического оборудования.
              </v-card-title>
              <v-row>
                <v-col cols="6">
                  <v-card-title>
                    Нарушения изоляции вызываются:
                  </v-card-title>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    перенапряжениями;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    ударами молний;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    старением изоляций;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    неудовлетворительным уходом за оборудованием;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    механическими повреждениями изоляций.
                  </v-card-text>
                </v-col>
                <v-col cols="6">
                  <v-card-title>
                    Признаки КЗ:
                  </v-card-title>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    мигание ламп;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    остановка электродвигателей;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    пиковые показания приборов;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    оплавление медных проводов (шарики).
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                Последствия КЗ:
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    термические и механические повреждения электрооборудования;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    возгорание в электроустановках;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    понижение напряжения в электросети, которое приводит к снижению вращающего момента электрических двигателей, понижению производительности, остановке и поломке;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    выпадения из синхронизма частей электросистемы, которое приводит к авариям и ЧС;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    непосредственное электромагнитное влияние на коммуникации и линии связи.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                Методы защиты от КЗ:
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    установка электрических реакторов, ограничивающих подачу электротока;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    использование распараллеливания электроцепей (отключают шиносоединительные и секционные выключатели);
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    применение отключающего оборудования – автоматических выключателей и плавких предохранителей;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    применение понижающих трансформаторов, использующих расщепленную обмотку низкого напряжения.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab1: null
  }),
  components: {
    Hint
  }
}
</script>
