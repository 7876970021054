<template>
  <div>
    <v-container class="noselect">
      <v-row>
        <v-col cols="12">
          <v-tabs
          v-model="tab2"
          grow
          color="red"
          class="red"
          >
            <v-tab class="red lighten-5 mr-5">Причины</v-tab>
            <v-tab class="red lighten-5 mr-5">Последствия</v-tab>
            <v-tab class="red lighten-5 mr-5">Профилактика</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12">
          <v-tabs-items v-model="tab2">
            <v-tab-item>
              <v-card-title>
                Причины больших переходных сопротивлений:
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    Неплотный контакт и неровность в местах соединения и оконцевания проводов (особенно при наличии вибрации оборудования);
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    малая сила сжатия контактирующих проводников;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    уменьшение сечения в месте соединения;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    окисления –пленки окиси меди, алюминия и других металлов.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                Последствия:
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    увеличивается количество выделяющегося тепла. Если нагретые контакты соприкасаются с горючими или взрывоопасными материалами, то возможно их воспламенение или взрыв;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                    места с наличием переходных сопротивлений трудно обнаружить, а защитные аппараты сетей и установок не могут предупредить возникновение пожара, так как электрический ток в цепи не возрастает.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card-title>
                Профилактика переходных соединений:
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    тщательное и правильное соединение проводников между собой (скрутка с последующей пропайкой, сварка, опрессовка);
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    применение на съемочных контактах специальных наконечников;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    создание трущихся контактов;
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    покрытие контактов специальными составами (лужение, серебрение и т. п.);
                  </v-card-text>

                  <v-card-text class="title font-weight-light">
                    <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                    подпружинивание контактов.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
