<template>
  <div class="noselect">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title>
           <span class="red--text font-weight-bold">Электрическая перегрузка</span> – режим работы, когда по проводам и кабелям идет рабочий ток больше допустимого.
          </v-card-title>
        </v-col>
        <v-col cols="5">
          <v-card  flat class="pa-4">
            <v-card-title>
              Причины перегрузки:
            </v-card-title>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on }">
                  <span
                    class="blue lighten-4"
                    v-on="on"
                  >несоответствие сечения проводников </span>
                </template>
                <span class="pa-5 subtitle-1 ">например, электропроводка к звонку выполняется телефнным проводом</span>
              </v-tooltip>

              рабочему току;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on }">
                  <span
                    class="blue lighten-4"
                    v-on="on"
                  >  параллельное включение в сеть </span>
                </template>
                <span class="pa-5 subtitle-1 ">например, подключение удлиннителя с 3 - 4 розетками в одну рабочую</span>
              </v-tooltip>
              непредусмотренных расчетом токоприемников без увеличения сечения проводников;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              попадание на проводники токов утечки, молнии;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              повышение температуры окружающей среды.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card flat class="pa-5">
            <v-card-title>
              Последствия перегрузки:
            </v-card-title>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              перегрев проводов, кабелей, обмоток машин и аппаратов;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              окисление контактов и мест соединения проводов;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              старение и износ изоляции;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              воспламенение изоляции;
            </v-card-text>

            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              тепловой пробой и как следствие КЗ;
            </v-card-text>

            <v-card-text class="title font-weight-light mb-6">
              <v-icon color="red" class="mr-3">mdi-alert</v-icon>
              аварийный выход из строя электроприборов и аппаратов.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    //
  })
}
</script>
